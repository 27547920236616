<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="收货人姓名">
                <a-input v-model="queryParam.receiving_name" placeholder="请输入姓名" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.mallshop_order_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text/1).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handle_create_pick_list(record)" v-if="record.status === 9">创建捡货单</a>
            <a-divider type="vertical" v-if="record.status === 9"/>
            <a @click="handleDetail(record)">详情</a>
            <a-divider type="vertical" v-if="record.status === 10"/>
            <a @click="handle_to_examine(record)" v-if="record.status === 10">审核</a>
            <a-divider type="vertical" v-if="isRefund(record.status)"/>
            <a @click="handleRefund(record)" v-if="isRefund(record.status)">退款</a>
          </template>
        </span>
      </s-table>
      <a-modal v-model="select_status_visible" title="审核" ok-text="确认" cancel-text="取消" @ok="select_status">
        <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.order_exam_status"
            :key="key"
            :value="key">
            {{ val }}
          </a-select-option>
        </a-select>
        <br />
        <br />
        <span v-if="select_status_data === '5'">
          退款金额：<a-input-number :min="0" v-model="refund" style="width: 180px"/> 元</span>
      </a-modal>
      <a-modal v-model="refund_status_visible" title="退款" ok-text="确认" cancel-text="取消" @ok="select_refund">
        <a-row :gutter="2">
          <a-col :md="12" :sm="24">
            <a-form-item label="退款金额(元)">
              <a-input-number :min="0" v-model="refund" style="width: 180px"/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="退款理由">
              <a-input v-model="refund_reason" placeholder="请输入退款理由"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { post_code_draft_list } from '@/api/applet_code_draft'
import { create_pick_list, order_refund, order_to_examine, orderList } from '@/api/wx_shopmall_order'
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      tag_value: '',
      dataSource_tag: [],
      tags: [],
      allTags: [],
      selectedTags: [],
      select_status_visible: false,
      refund_status_visible: false,
      select_status_data: '请选择',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '收货人',
          dataIndex: 'receiving_name'
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.mallshop_order_status[text] || '无'
        },
        {
          title: '模式',
          dataIndex: 'delivery_method',
          customRender: (text) => this.$Dictionaries.deliveryMethod[text] || '无'
        },
        {
          title: '订单金额',
          dataIndex: 'goods_value',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '支付金额',
          dataIndex: 'actural_paid',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '下单时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCommit (record) {
      post_code_draft_list(record.draft_id).then(res => {
        console.log(res)
        this.$message.success('提交成功')
        this.$refs.table.refresh()
      })
    },
    handleDetail (record) {
      console.log(record.id)
      this.$router.push({ path: '/shopmall/order/detail', query: { id: record.id } })
    },
  //  手动补订单库存数量
    handle_create_pick_list (record) {
      create_pick_list({ order_id: record.id }).then(({ data }) => {
        console.log(data)
      })
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      order_to_examine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.$refs.table.refresh()
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: this.refund * 100,
        reason: this.refund_reason,
        order_id: this.order_id
      }

      order_refund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_examine (record) {
      this.select_status_visible = true
      // 2 审核通过，5 审核不通过
      this.order_id = record.id
    },
    // 订单退款
    handleRefund (record) {
      this.refund_status_visible = true
      // 2 审核通过，5 审核不通过
      this.order_id = record.id
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    }
  }
}
</script>
