import request from '@/utils/request'

const draftApi = {
  get_code_draft_list: '/get_code_draft_list/',
  post_code_draft_list: '/get_code_draft_list/'
}

/**
 * 获取代码草稿列表
 */
export function get_code_draft_list (parameter) {
  return request({
    url: draftApi.get_code_draft_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 提交到代码草稿
 */
export function post_code_draft_list (parameter) {
  return request({
    url: draftApi.post_code_draft_list,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
